Vue.component('wheelicon', {
  template: `
  <div class="wheelicon" :class="'wheelicon-' + icon">
    <div class="wheelicon-image">
      <img :src="fullurl" @error="png = true"></img>
    </div>
    <div class="ui popup popup-content" style="width:200px" v-if="hasSlotData">
      <slot></slot>
    </div>
  </div>
  `,
  data: function () {
    return {
      basePath: '/skin/frontend/mediadivision/oz/img/wheelicons/',
      png: false
    }
  },
  props: [
    'icon'
  ],
  computed: {
    'hasSlotData': function() {
    	return this.$slots.default;
    },
    'fullurl': function() {
      var ext = this.png?'.png':'.svg';
      return this.basePath + this.icon + ext;
    }
  },
  mounted: function() {
    var app = this;
    var el = jQuery(app.$el);
    var trigger = el.find('.wheelicon-image');
    var popupel = el.find('.popup-content');

    if(popupel.length > 0) {
      trigger.popup({
        popup: popupel[0],
        boundary: '.wrapper',
        position: 'top center',
        preserve: false,
        hoverable: true,
        delay: {
            show: 0,
            hide: 200
        },
        transition: 'fade up'
      });
    }
  }
})